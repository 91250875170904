
import { FilterSettings, Orientations, UserSettings as UserSettings_TYPE } from "./variables";
import {
	contains, contains_UserSettings, Country_to_Languages, empty_UserSettings, full_FilterSettings,
	full_UserSettings, Get_Location, Reset
} from "./functions";

import { Network as UserNetwork } from "./networks/user";





export class Error {

	private readonly MESSAGES: { [k: string]: string } = {
		"default": "Stremix ran into an error",
		"unexpected": "Stremix ran into an unexpected error",
		"database_not_connected": "Database is unreachable",
		"request_timeout": "Request Timeout",
		"access_token_invalid": "Session expired. Please reload page",
		"version": "Please update application. You are using an older version",

		"unauthorized": "Unauthorized request"
	};

	code: string;
	status_code: number;
	message_id: string;
	message: string;

	constructor(code: string, status_code: number = 500, message_id: string = "", message: string = "") {
		this.code = code;
		this.status_code = status_code;

		// console.log(code, status_code, message_id, message);

		this.message_id = "default";
		this.message = this.MESSAGES[this.message_id];

		if (contains(message_id)) {
			if (message_id !== "error") this.message_id = message_id;
			if (this.message_id in this.MESSAGES) this.message = this.MESSAGES[this.message_id];
		}

		if (contains(message) && (message !== "error")) this.message = message;
	}

	getMessage(): string { return `Error: ${this.message}`; }
	getCode(): string { return `Code: ${this.code}`; }
}



export class System {
	oldScroll: number = 0;
	readonly SWIPE_THRESHOLD: number = 15;
	initialX?: number = undefined;
	initialY?: number = undefined;

	fullscreen: boolean = false;
	_CONTROL_HELD_DOWN_: boolean = false;

	orientation: Orientations;

	modal_LoginAlert_OPEN: boolean = false;

	readonly filter_settings: FilterSettings;

	constructor(filter_settings: FilterSettings = full_FilterSettings()) {
		this.filter_settings = filter_settings;

		try {
			this.orientation = (
				["landscape-primary", "landscape-secondary"].includes(window.screen.orientation.type)
			) ? "landscape" : "portrait";
		} catch (err) {
			this.orientation = (window.innerWidth > window.innerHeight) ? "landscape" : "portrait";
		}

		window.addEventListener("resize", (): void => {
			try {
				this.orientation = (
					["landscape-primary", "landscape-secondary"].includes(window.screen.orientation.type)
				) ? "landscape" : "portrait";
			} catch (err) {
				this.orientation = (window.innerWidth > window.innerHeight) ? "landscape" : "portrait";
			}
		}, true);
	}
}



export class User {
	readonly network: UserNetwork = new UserNetwork();

	readonly system: System;
	readonly logged_in: boolean;
	settings?: UserSettings_TYPE;
	ALLOW_UPDATE: boolean = false;

	constructor(system: System, logged_in: boolean = false) {
		this.system = system;
		this.logged_in = logged_in;
	}

	async initiate_Settings(settings?: UserSettings_TYPE): Promise<void> {

		if (settings && contains_UserSettings(settings)) {
			this.settings = settings;
			if (this.logged_in) {
				localStorage.removeItem("settings");
				this.ALLOW_UPDATE = true;
			}
			this.update_localStorage();
			return;
		}

		let new_user: boolean = false;
		const l: string | null = localStorage.getItem("settings");
		if (l && contains(l)) {
			try {
				this.settings = JSON.parse(l);
				this.update_Settings_Online();
			} catch (err) {
				new_user = true;
			}
		} else new_user = true;

		if (new_user) {
			const availabilities: string[] = [];
			for (const a of this.system.filter_settings.availabilities) availabilities.push(a.id);
			this.settings = full_UserSettings(availabilities);
			this.settings.languages = Country_to_Languages(await Get_Location());
			this.update_Settings();
		}
	}

	match_stored_Settings(s: UserSettings_TYPE): boolean {
		const i: string | null = localStorage.getItem("settings");
		if (i && contains(i)) return (JSON.stringify(s) === i);
		return contains_UserSettings(s);
	}

	update_Settings(): void {
		this.update_localStorage();
		this.update_Settings_Online();
		// console.log(JSON.parse(localStorage.getItem("settings") as string));
	}

	private update_localStorage(): void {
		localStorage.removeItem("settings");
		localStorage.setItem("settings", JSON.stringify(this.settings));
	}

	private update_Settings_Online_ALLOW: boolean = true;
	update_Settings_Online(): void {
		if (
			!this.logged_in || !this.settings || !contains(this.settings) || !this.ALLOW_UPDATE ||
			!this.update_Settings_Online_ALLOW
		) return;

		this.update_Settings_Online_ALLOW = false;

		this.network.PATCH_UserSettings(this.settings)
		.then((b: boolean): void => {
			// console.clear();
			// console.log(b);
			if (b) this.update_localStorage();
			this.update_Settings_Online_ALLOW = true;
		}).catch((error: Error): void => {
			// console.clear();
			// console.log(error);

			if ((error instanceof Error) && (error.message_id === "access_token_invalid")) {
				Reset();
				window.location.reload();
				return;
			}

			this.update_Settings_Online_ALLOW = true;
		});
	}
}





/*
	--------------
	Form Inputs
	--------------
*/

export class AccountName {
	private account_name: string;
	status: null | Error = null;

	constructor(account_name: string = "") {
		this.account_name = account_name;
	}

	get(): string { return this.account_name; }

	set(s: string): void {
		this.account_name = s;

		const e: Error = new Error("HuFotyoLmr");

		if (!contains(s))
			e.message = "This field is empty";
		else if (s.length < 5)
			e.message = "The lenght of the Account Name should exceed 5 characters";
		else if (s.length > 20)
			e.message = "The lenght of the Account Name cannot exceed 20 characters";
		else if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(s))
			e.message = "Account Name cannot contain 'email' address";
		else {
			this.status = null;
			return;
		}

		this.status = e;
	}
}

export class Dob {
	private dob: Date;
	status: null | Error = null;

	constructor(dob: Date = new Date()) {
		this.dob = dob;
	}

	get(): Date { return this.dob; }

	set(d: Date): void {
		this.dob = d;

		const e: Error = new Error("YhhrKNyMH1");

		// if (isNaN(Date.parse(d))
		//     e.message = "Date of Birth not valid. The entry in not 'Date' type";
		if ((new Date().getFullYear() - new Date(d).getFullYear()) < 3)
			e.message = "The date needs to be atleast 3 years back. This is not a valid range";
		if ((new Date().getFullYear() - new Date(d).getFullYear()) > 125)
			e.message = "The date is 125 years back. This is not a valid range";
		else if (new Date(d) >= new Date())
			e.message = "The date is a future date. This is not a valid range";
		else {
			this.status = null;
			return;
		}

		this.status = e;
	}
}

export class Email {
	private email: string;
	status: null | Error = null;

	constructor(email: string = "") {
		this.email = email;
	}

	get(): string { return this.email; }

	set(s: string): void {
		this.email = s;

		const e: Error = new Error("gkVqv76EJl");

		if (!contains(s))
			e.message = "This field is empty";
		else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(s))
			e.message = "Email entry not valid 'email'";
		else {
			this.status = null;
			return;
		}

		this.status = e;
	}
}

export class Password {
	private password: string;
	status: null | Error = null;

	constructor(password: string = "") {
		this.password = password;
	}

	get(): string { return this.password; }

	set(s: string): void {
		this.password = s;

		const e: Error = new Error("J6a51s3xtd");

		if (!contains(s))
			e.message = "This field is empty";
		else if (!(/[0-9]/.test(s)))
			e.message = "Password should contain at least 1 number";
		else if (!(/[a-zA-Z]/.test(s)))
			e.message = "Password should contain at least 1 letter";
		else if (!(/['^£$%&*()!}{@#~?><>,|=_+¬-]/.test(s)))
			e.message = "Password should contain at least 1 special character";
		else if (s.length < 8)
			e.message = "The length of Password is less than 8";
		else if (s.length > 30)
			e.message = "The length of Password is more than 30";
		else {
			this.status = null;
			return;
		}

		this.status = e;
	}
}

export class ConfirmPassword {
	private confirm_password: string;
	password: Password;
	status: null | Error = null;

	constructor(password: Password, confirm_password: string = "") {
		this.password = password;
		this.confirm_password = confirm_password;
	}

	get(): string { return this.confirm_password; }

	set(s: string): void {
		this.confirm_password = s;

		const e: Error = new Error("9O1Yr26SLG");

		if (!contains(s))
			e.message = "This field is empty";
		else if (this.password.get() !== s)
			e.message = "Entered Password and Confirm-Password don't match";
		else {
			this.status = null;
			return;
		}

		this.status = e;
	}
}

export class UserSettings {
	settings: UserSettings_TYPE;
	status: null | Error = null;

	constructor(settings: UserSettings_TYPE = empty_UserSettings()) {
		this.settings = settings;
	}

	get(): UserSettings_TYPE { return this.settings; }

	set(s: UserSettings_TYPE): void {
		this.settings = s;

		const e: Error = new Error("fukyAgdiUt");

		if (!contains_UserSettings(s))
			e.message = "Not Valid";
		else {
			this.status = null;
			return;
		}

		this.status = e;
	}
}
