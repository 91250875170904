
import axios, { AxiosError, AxiosResponse } from "axios";

import { UserSettings } from "src/utils/variables";
import { contains, Get_Access_Token, Parse_Backend_Error } from "src/utils/functions";
import { Error } from "src/utils/classes";





export class Network {

	private GET_UserSettings_ALLOW: boolean = true;
	GET_UserSettings(): Promise<UserSettings> {
		return new Promise<UserSettings>((
			resolve: (value: UserSettings) => void, reject: (reason: Error) => void
		): void => {
			if (!this.GET_UserSettings_ALLOW) return;
			this.GET_UserSettings_ALLOW = false;

			// Headers
			const headers: { [k: string]: string } = {},
						access_token: string | null = Get_Access_Token();
			if (contains(access_token)) headers["Authorization"] = `Bearer ${access_token}`;

			axios.request({
				method: "GET",
				url: "user/settings",
				headers: headers,
				responseEncoding: "utf8",
				responseType: "json",
				timeout: 15000,
				withCredentials: true
			}).then((res: AxiosResponse): void => {
				// console.clear();
				// console.log(res.data);

				if (res.status === 200) {
					resolve(res.data as UserSettings);
				} else if (res.data["status"] === "error") {
					reject(Parse_Backend_Error(res.data));
				} else reject(new Error("SfQMIV7dwA"));

			}).catch((error: AxiosError): void => {
				// console.clear();

				const d: any = error.response?.data;
				// console.log(d);

				if (
					(error.response?.status === 401) && ("code" in d) && (d["code"] === "token_not_valid")
				) reject(new Error("KfgzRDRDqi", 401, "access_token_invalid"));
				else reject(Parse_Backend_Error(d));

			}).finally((): void => {
				this.GET_UserSettings_ALLOW = true;
			});
		});
	}



	private PATCH_UserSettings_ALLOW: boolean = true;
	PATCH_UserSettings(us: UserSettings): Promise<boolean> {
		return new Promise<boolean>((
			resolve: (value: boolean) => void, reject: (reason: Error) => void
		): void => {
			if (!this.PATCH_UserSettings_ALLOW) return;
			this.PATCH_UserSettings_ALLOW = false;

			// Headers
			const headers: { [k: string]: string } = {},
						access_token: string | null = Get_Access_Token();
			if (contains(access_token)) headers["Authorization"] = `Bearer ${access_token}`;

			axios.request({
				method: "PATCH",
				url: "user/settings",
				headers: headers,
				data: { settings: us },
				responseEncoding: "utf8",
				responseType: "json",
				timeout: 15000,
				withCredentials: true
			}).then((res: AxiosResponse): void => {
				// console.log(res.data);

				if (res.status === 204) {
					resolve(true);
				} else if (res.data["status"] === "error") {
					reject(Parse_Backend_Error(res.data));
				} else reject(new Error("NXmFdWrC0l"));

			}).catch((error: AxiosError): void => {
				// console.clear();

				const d: any = error.response?.data;
				// console.log(d);

				if (
					(error.response?.status === 401) && ("code" in d) && (d["code"] === "token_not_valid")
				) reject(new Error("vFgvx5ttof", 401, "access_token_invalid"));
				else reject(Parse_Backend_Error(d));

			}).finally((): void => {
				this.PATCH_UserSettings_ALLOW = true;
			});
		});
	}



	private DELETE_Logout_ALLOW: boolean = true;
	DELETE_Logout(): Promise<boolean> {
		return new Promise<boolean>((
			resolve: (value: boolean) => void, reject: (reason: Error) => void
		): void => {
			if (!this.DELETE_Logout_ALLOW) return;
			this.DELETE_Logout_ALLOW = false;

			// Headers
			const headers: { [k: string]: string } = {},
						access_token: string | null = Get_Access_Token();
			if (contains(access_token)) headers["Authorization"] = `Bearer ${access_token}`;

			axios.request({
				method: "DELETE",
				url: "logout",
				headers: headers,
				responseEncoding: "utf8",
				responseType: "json",
				timeout: 25000,
				withCredentials: true
			}).then((res: AxiosResponse): void => {
				// console.clear();
				// console.log(res.data);

				if (res.status === 200) {
					resolve(true);
				} else if (res.data["status"] === "error") {
					reject(Parse_Backend_Error(res.data));
				} else reject(new Error("gzbjngQQ12"));

			}).catch((error: AxiosError): void => {
				// console.clear();

				const d: any = error.response?.data;
				// console.log(d);

				if (
					(error.response?.status === 401) && ("code" in d) && (d["code"] === "token_not_valid")
				) reject(new Error("nSmXHAMzO6", 401, "access_token_invalid"));
				else reject(Parse_Backend_Error(d));

			}).finally((): void => {
				this.DELETE_Logout_ALLOW = true;
			});
		});
	}
}
