


export type Orientations = ("portrait" | "landscape");



export type Type =                  ("movie" | "serie");
export const Type_ALL_VAL: Type[] = ["movie", "serie"];

/* eslint-disable max-len */
export type PRContent_Type =                            ("blopper" | "compilation" | "deleted-scene" | "featurette" | "promo" | "scene" | "spoiler" | "teaser" | "trailer");
export const PRContent_Type_ALL_VAL: PRContent_Type[] = ["blopper" , "compilation" , "deleted-scene" , "featurette" , "promo" , "scene" , "spoiler" , "teaser" , "trailer"];

export type Country =                     ("argentina" | "australia" | "bangladesh" | "belgium" | "brazil" | "canada" | "china" | "colombia" | "czech-republic" | "denmark" | "egypt" | "france" | "germany" | "greece" | "hong-kong" | "hungary" | "iceland" | "india" | "indonesia" | "iran" | "ireland" | "israel" | "italy" | "japan" | "mexico" | "nepal" | "netherlands" | "new-zealand" | "norway" | "pakistan" | "philippines" | "poland" | "romania" | "russia" | "saudi-arabia" | "south-africa" | "south-korea" | "spain" | "sweden" | "switzerland" | "taiwan" | "thailand" | "turkey" | "uk" | "ukraine" | "usa");
export const Country_ALL_VAL: Country[] = ["argentina" , "australia" , "bangladesh" , "belgium" , "brazil" , "canada" , "china" , "colombia" , "czech-republic" , "denmark" , "egypt" , "france" , "germany" , "greece" , "hong-kong" , "hungary" , "iceland" , "india" , "indonesia" , "iran" , "ireland" , "israel" , "italy" , "japan" , "mexico" , "nepal" , "netherlands" , "new-zealand" , "norway" , "pakistan" , "philippines" , "poland" , "romania" , "russia" , "saudi-arabia" , "south-africa" , "south-korea" , "spain" , "sweden" , "switzerland" , "taiwan" , "thailand" , "turkey" , "uk" , "ukraine" , "usa"];

export type Language =                      ("arabic" | "bengali" | "cantonese" | "czech" | "danish" | "dutch" | "english" | "filipino" | "finnish" | "french" | "german" | "greek" | "hebrew" | "hindi" | "hungarian" | "icelandic" | "indonesian" | "irish" | "italian" | "japanese" | "kannada" | "korean" | "latin" | "malayalam" | "mandarin" | "marathi" | "nepali" | "norwegian" | "persian" | "polish" | "portuguese" | "punjabi" | "romanian" | "russian" | "spanish" | "swedish" | "tamil" | "telugu" | "thai" | "turkish" | "ukrainian" | "urdu" | "xhosa");
export const Language_ALL_VAL: Language[] = ["arabic" , "bengali" , "cantonese" , "czech" , "danish" , "dutch" , "english" , "filipino" , "finnish" , "french" , "german" , "greek" , "hebrew" , "hindi" , "hungarian" , "icelandic" , "indonesian" , "irish" , "italian" , "japanese" , "kannada" , "korean" , "latin" , "malayalam" , "mandarin" , "marathi" , "nepali" , "norwegian" , "persian" , "polish" , "portuguese" , "punjabi" , "romanian" , "russian" , "spanish" , "swedish" , "tamil" , "telugu" , "thai" , "turkish" , "ukrainian" , "urdu" , "xhosa"];

export type Genre =                   ("action" | "adventure" | "animation" | "biography" | "comedy" | "crime" | "documentary" | "drama" | "family" | "fantasy" | "game-show" | "history" | "horror" | "kids" | "music" | "musical" | "mystery" | "news" | "politics" | "reality" | "reality-tv" | "romance" | "sci-fi" | "short" | "sport" | "soap" | "talk-show" | "thriller" | "war" | "western");
export const Genre_ALL_VAL: Genre[] = ["action" , "adventure" , "animation" , "biography" , "comedy" , "crime" , "documentary" , "drama" , "family" , "fantasy" , "game-show" , "history" , "horror" , "kids" , "music" , "musical" , "mystery" , "news" , "politics" , "reality" , "reality-tv" , "romance" , "sci-fi" , "short" , "sport" , "soap" , "talk-show" , "thriller" , "war" , "western"];

export type Age_Rating =                        (18 | 15 | 13 | 9 | 3);
export const Age_Rating_ALL_VAL: Age_Rating[] = [18 , 15 , 13 , 9 , 3];

export type Release_Year =                          (2024 | 2023 | 2022 | 2021 | 2020 | 2019 | 2018 | 2015 | 2010 | 2005 | 2000 | 1995 | 1990 | 1985 | 1980 | 1975 | 1970);
export const Release_Year_ALL_VAL: Release_Year[] = [2024 , 2023 , 2022 , 2021 , 2020 , 2019 , 2018 , 2015 , 2010 , 2005 , 2000 , 1995 , 1990 , 1985 , 1980 , 1975 , 1970];
/* eslint-enable max-len */



/*
	----- Endpoint Values -----
*/

export type Sort_By =                     ("shuffle" | "content_date" | "prcontent_date");
export const Sort_By_ALL_VAL: Sort_By[] = ["shuffle" , "content_date" , "prcontent_date"];

export type AvailabilityType =                              ("buy" | "rent" | "subscription");
export const AvailabilityType_ALL_VAL: AvailabilityType[] = ["buy" , "rent" , "subscription"];





export type InsertDeleteToggle = "insert" | "delete";

/*
	----- Availability Provider -----
*/

export interface AvailabilityProvider {
	readonly id: string;
	readonly account_name: string;
};





/*
	----- User Data -----
*/

export interface Provider {
	readonly id: string;
	readonly account_name: string;
	readonly availability_id_user: string | null;
	readonly followers: number;

	readonly user?: {
		following: boolean | null;
	};
};

interface UserContentData {
	following: boolean | null;
	rating: number | null;
	saved: boolean | null;
	watched: boolean | null;
};

interface UserPRContentData {
	reaction: boolean | null;
	saved: boolean | null;
};

interface UserGroupData {
	saved: boolean;
};





/*
	----- Promotion Content -----
*/

export interface PRContent {
	readonly id: string;
	readonly type: PRContent_Type;
	readonly title: string;
	readonly id_youtube: string;
	readonly season?: number;
	readonly episode?: number;
	readonly provider: Provider;
	views_total: number;
	reaction_count: number;
	readonly upload_dt: Date;

	readonly user?: UserPRContentData;
};





/*
	----- Content -----
*/

export interface Content {
	readonly type: Type;
	readonly id: string;
	readonly title: string;
	readonly poster_url: string;
	readonly release_date?: Date;
	readonly provider: Provider;
	readonly prcontents: PRContent[];
	readonly prcontents_total: number;

	readonly user?: UserContentData;
};





/*
	----- Availability -----
*/

export interface Availability {
	readonly id: string;
	readonly provider: Provider;
	readonly type: AvailabilityType;
	readonly url: string;
};





/*
	----- Settings -----
*/

interface Settings {
	types: Type[];
	prcontent_types: PRContent_Type[];
	languages: Language[];
	genres: Genre[];
	age_ratings: Age_Rating[];
};

export interface FilterSettings extends Settings {
	readonly sort_bys: Sort_By[];
	readonly availabilities: AvailabilityProvider[];
	readonly release_years: Release_Year[];
};

export interface UserSettings extends Settings {
	auto_play: boolean;
	auto_fit_middle: boolean;
	availabilities: string[];
	sort_by: Sort_By;
	release_year: Release_Year;
};





/*
	----- Search Results -----
*/

export interface SearchResult {
	type: Type;
	id: string;
	title: string;
	year: number;
	poster_url: string;
	row_insert_dt?: Date;
};





/*
	----- Group -----
*/

export interface Group {
	id: string;
	title: string;
	user?: UserGroupData;
};





/*
	----- Networks -----
*/

export interface Excludes {
	readonly movies: string[];
	readonly series: string[];
};





/*
	----- Schema Markup -----
*/

export interface SchemaMarkup {
	type: "application/ld+json";
	innerHTML: string;
};





/*
	----- Shares -----
*/

export interface Shares {
	readonly name: string;
	readonly title: string;
	readonly url: URL;
};
