
import $ from "jquery";

import axios, { AxiosResponse } from "axios";

import {
	Age_Rating, Age_Rating_ALL_VAL, AvailabilityProvider, Country, Country_ALL_VAL, FilterSettings,
	Genre, Genre_ALL_VAL, Language, Language_ALL_VAL, PRContent, PRContent_Type, PRContent_Type_ALL_VAL,
	Release_Year_ALL_VAL, SearchResult, Sort_By_ALL_VAL, Type, Type_ALL_VAL, UserSettings
} from "./variables";
import { Error } from "./classes";





/*
	-------------------
	Variable Parsers
	-------------------
*/

export const Parse_PRContent = (d: any): PRContent => {

	try {
		if ("upload_dt" in d) d["upload_dt"] = new Date(d["upload_dt"]);
	} catch (err) { throw new Error("PMUdr06wm3"); }

	return d as PRContent;
};

export const Parse_SearchResult = (d: any): SearchResult => {

	if ("row_insert_dt" in d) d["row_insert_dt"] = new Date(d["row_insert_dt"]);

	return d as SearchResult;
};



/*
	----------
	General
	----------
*/

export const att = (s: string, b?: boolean): any => {
	if ((b !== undefined) && (b === false)) return {};
	return { [s]: '' };
};

export const contains = (...y: any): boolean => {
	for (const x of y) if (
		(x === undefined) || (x === null) ||
		(x === "") || (x.length === 0) ||
		((typeof x === "number") && isNaN(x))
	) return false;
	return true;
};

export const setTitle = (...s: string[]): string => { return `${s.join(" | ")} | Stremix`; };

export const Generate_Rand_Text = (l: number, secret: boolean = false): string => {
	const characters: string = (
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
	) + (secret ? "[@_.!$%^&*()<>?|}{~:]" : '');
	let _return: string = '';
	for (let i: number = 0; i < l; i++) {
		_return += characters.charAt(Math.floor(Math.random() * characters.length));
	}
	return _return;
};

export const Reset = (): void => {
	window.localStorage.clear();
};



/*
	--------
	Error
	--------
*/

export const Parse_Backend_Error = (d: any): Error => {
	try {
		if (d === undefined) return new Error("CwUqHgZPWG", 500, "undefined");
		else if (d === null) return new Error("m5uqKGjk8r", 500, "null");
		else if (contains(d) && (d["status"] === "error"))
			return new Error(d["code"], parseInt(d["status_code"]), d["message_id"], d["message"]);
	/* eslint-disable-next-line no-empty */
	} catch (err) {}
	return new Error("Akwvq2OFeh");
};



/*
	--------
	Token
	--------
*/

export const Get_Access_Token = (): string | null => {
	return window.localStorage.getItem("access_token");
};

export const Set_Access_Token = (token: string): void => {
	window.localStorage.setItem("access_token", token);
};

export const Delete_Access_Token = (): void => {
	window.localStorage.removeItem("access_token");
};



/*
	---------------
	Notification
	---------------
*/

export const Get_Notification_Registration_ID = (): Promise<string> => {
	return new Promise<string>((
		resolve: (value: string) => void, reject: (reason: Error) => void
	): void => {
		window.Notification.requestPermission()
		.then((d: NotificationPermission): void => {
			if (d === "granted") {
				// console.log(window.navigator.serviceWorker.ready);
				window.navigator.serviceWorker.ready
				.then((registration: ServiceWorkerRegistration): void => {

					// console.log(registration);
					registration.pushManager.subscribe({
						userVisibleOnly: true,
						applicationServerKey: "Your Public VAPID Key",
					}).then((/* subscription: PushSubscription */): void => {
						// console.log('Push notification subscription:', subscription);
					}).catch((): void => {
						reject(new Error("wzkAPrsNlI"));
					});
				}).catch((/* error: any */): void => {
					// console.log(error);
					reject(new Error("EbTbaG46fZ"));
				});
				return;
			}
			reject(new Error("gagYaDkoDI"));

		}).catch((): void => {
			reject(new Error("joNBzng7Pv"));
		});
	});
};



/*
	------------------
	Filter Settings
	------------------
*/

export const full_FilterSettings = (availabilities: AvailabilityProvider[] = []): FilterSettings => {
	return {
		types: Type_ALL_VAL,
		prcontent_types: PRContent_Type_ALL_VAL,
		availabilities: availabilities,
		languages: Language_ALL_VAL,
		genres: Genre_ALL_VAL,
		sort_bys: Sort_By_ALL_VAL,
		age_ratings: Age_Rating_ALL_VAL,
		release_years: Release_Year_ALL_VAL
	};
};



/*
	----------------
	User Settings
	----------------
*/

export const contains_UserSettings = (s: UserSettings): boolean => {
	try {
		if (!contains(s)) return false;
		if (!contains(
			s.auto_play, s.auto_fit_middle,
			s.types, s.prcontent_types, s.availabilities, s.languages, s.genres,
			s.sort_by, s.age_ratings, s.release_year
		)) return false;

		// Boolean Checks
		for (const x of [s.auto_play, s.auto_fit_middle])
			if ((typeof x) !== "boolean") return false;

		// List Checks
		for (const x of [s.types, s.prcontent_types, s.availabilities, s.languages, s.genres, s.age_ratings])
			if (!(x instanceof Array)) return false;

		// Single Value Checks
		if (
			!Sort_By_ALL_VAL.includes(s.sort_by) ||
			!Release_Year_ALL_VAL.includes(s.release_year)
		) return false;

		return true;

	/* eslint-disable-next-line no-empty */
	} catch (err) {}
	return false;
};

export const empty_UserSettings = (availabilities: string[] = []): UserSettings => {
	return {
		auto_play: false,
		auto_fit_middle: false,
		types: [],
		prcontent_types: [],
		availabilities: availabilities,
		languages: [],
		genres: [],
		sort_by: Sort_By_ALL_VAL[0],
		age_ratings: [],
		release_year: Release_Year_ALL_VAL[0]
	};
};

export const full_UserSettings = (availabilities: string[] = []): UserSettings => {
	return {
		auto_play: false,
		auto_fit_middle: false,
		types: Type_ALL_VAL,
		prcontent_types: PRContent_Type_ALL_VAL,
		availabilities: availabilities,
		languages: Language_ALL_VAL,
		genres: Genre_ALL_VAL,
		sort_by: Sort_By_ALL_VAL[0],
		age_ratings: Age_Rating_ALL_VAL,
		release_year: Release_Year_ALL_VAL[Release_Year_ALL_VAL.length - 1]
	};
};



/*
	---------------
	Content Data
	---------------
*/

export const toRating = (r: Age_Rating): string => {
	if (r === 3) {
		// return "TV-G";
		return 'G';
	} else if (r === 9) {
		// return "TV-Y7";
		return "PG";
	} else if (r === 13) {
		// return "TV-PG";
		return "PG-13";
	} else if (r === 15) {
		// return "TV-14";
		return "TV-MA";
	}
	return 'R';
};



/*
	------------------
	Window Handlers
	------------------
*/

export const Window_X = (parent: HTMLElement = document.approot): number => {
	if (parent === document.approot) {
		if (document.approot && (document.approot.scrollLeft > 0)) return document.approot.scrollLeft;
		else if (window.scrollX > 0) return window.scrollX;
	} else if (parent && (parent.scrollTop > 0)) return parent.scrollTop;
	return window.scrollX;
};

export const Window_Y = (parent: HTMLElement = document.approot): number => {
	if (parent === document.approot) {
		if (document.approot && (document.approot.scrollTop > 0)) return document.approot.scrollTop;
		else if (window.scrollY > 0) return window.scrollY;
	} else if (parent && (parent.scrollTop > 0)) return parent.scrollTop;
	return window.scrollY;
};

export const Element_is_Center_in_Window = (e: HTMLElement): boolean => {
	const middle_line_y: number = Window_Y() + (window.innerHeight / 2),
				middle_line_x: number = Window_X() + (window.innerWidth / 2),
				r: DOMRect = e.getBoundingClientRect();

	return (
		((r.top + Window_Y()) < middle_line_y) && ((r.bottom + Window_Y()) > middle_line_y) &&
		((r.left + Window_X()) < middle_line_x) && ((r.right + Window_X()) > middle_line_x)
	);
};

export const Center_Element_in_Window_X = (parent: HTMLElement = document.approot, e: HTMLElement): void => {
	try {
		const ow: number | undefined = $(e).outerWidth(false);
		if (ow) Scroll_Page(
			parent, 0, (e.getBoundingClientRect().left + Window_X(parent)) - ((window.innerWidth / 2) - (ow / 2))
		);
	/* eslint-disable-next-line no-empty */
	} catch (err) {}
};

export const Center_Element_in_Window_Y = (parent: HTMLElement = document.approot, e: HTMLElement): void => {
	try {
		const oh: number | undefined = $(e).outerHeight(false);
		if (oh) Scroll_Page(
			parent, (e.getBoundingClientRect().top + Window_Y(parent)) - ((window.innerHeight / 2) - (oh / 2)), 0
		);
	/* eslint-disable-next-line no-empty */
	} catch (err) {}
};

export const Scroll_Page = (parent: HTMLElement = document.approot, y?: number, x?: number): void => {
	// console.log(parent, y);
	if (parent === document.approot) {
		if (document.approot && (document.approot.scrollTop > 0)) {
			if (contains(y, x)) document.approot.scrollTo({ top: y, left: x, behavior: "smooth" });
			else if (contains(y)) document.approot.scrollTo({ top: y, left: Window_Y(parent), behavior: "smooth" });
			else if (contains(x)) document.approot.scrollTo({ top: Window_X(parent), left: x, behavior: "smooth" });
		} else {
			if (contains(y, x)) window.scrollTo({ top: y, left: x, behavior: "smooth" });
			else if (contains(y)) window.scrollTo({ top: y, left: Window_Y(parent), behavior: "smooth" });
			else if (contains(x)) window.scrollTo({ top: Window_X(parent), left: x, behavior: "smooth" });
		}
	} else if (parent && (parent.scrollTop > 0)) {
		if (contains(y, x)) parent.scrollTo({ top: y, left: x, behavior: "smooth" });
		else if (contains(y)) parent.scrollTo({ top: y, left: Window_Y(parent), behavior: "smooth" });
		else if (contains(x)) parent.scrollTo({ top: Window_X(parent), left: x, behavior: "smooth" });
	}
};

export const Remove_Browser_Smooth = (): void => {
	for (const e of [document.body, document.approot]) e.setAttribute("unset-scroll", '');
};



/*
	-----------------
	Image Handlers
	-----------------
*/

export const Set_Img_BG_Src = (e: HTMLImageElement): void => {
	e.setAttribute("loading_spinner", '');
};

export const Remove_Img_BG_Src = (e: HTMLImageElement): void => {
	e.removeAttribute("loading_spinner");
};



/*
	---------------------
	Date Time Handlers
	---------------------
*/

export const Get_Backend_DT = (d: Date = new Date()): string => {
	const microseconds: string = `${Math.floor(Math.random() * 10)}${Math.floor(Math.random() * 10)}${Math.floor(Math.random() * 10)}`,
				offset: number = -d.getTimezoneOffset() / 60;

	return `${d.getFullYear()}-${`${d.getMonth() + 1}`.padStart(2, '0')}-${`${d.getDate()}`.padStart(2, '0')} ${`${d.getHours()}`.padStart(2, '0')}:${`${d.getMinutes()}`.padStart(2, '0')}:${`${d.getSeconds()}`.padStart(2, '0')}.${d.getMilliseconds()}${microseconds}${(offset >= 0) ? '+' : '-'}${Math.abs(offset).toString().padStart(2, '0')}:00`;
};

export const Parse_Backend_DT = (dt: string): Date => {
	return new Date();
};

export const Format_Backend_Date = (d: Date = new Date()): string => {
	return `${d.getFullYear()}-${`${d.getMonth() + 1}`.padStart(2, '0')}-${`${d.getDate()}`.padStart(2, '0')}`;
};

export const Format_Date_for_DateTime_Local = (d: Date): string => {
	const year: number = d.getFullYear(),
				month: string = (d.getMonth() + 1).toString().padStart(2, '0'),
				day: string = d.getDate().toString().padStart(2, '0'),
				hour: string = d.getHours().toString().padStart(2, '0'),
				minute: string = d.getMinutes().toString().padStart(2, '0'),
				second: string = d.getSeconds().toString().padStart(2, '0');

	return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
};



/*
	-----------
	Location
	-----------
*/

interface Get_Location_Response {
	readonly country: Country;
};

export const Get_Location = async (): Promise<Country | null> => {
	// console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
	let _return: Country | null = null;

	await axios.request({
		method: "GET",
		url: "user/client_country",
		responseEncoding: "utf8",
		responseType: "json",
		timeout: 15000,
		withCredentials: true
	}).then((res: AxiosResponse): void => {
		// console.clear();
		// console.log(res.data);

		if (res.status === 200) {
			_return = (res.data as Get_Location_Response).country;
		}
	}).catch((/* error: AxiosError */): void => { _return = null; });

	return _return;
};

export const Country_to_Languages = (c: Country | null): Language[] => {
	if (c === null) return Language_ALL_VAL;

	if (["argentina", "colombia", "mexico", "spain"].includes(c)) return [ "english", "spanish" ];
	else if (["australia", "canada", "uk", "usa"].includes(c)) return [ "english" ];
	else if (c === "brazil") return [ "english", "portuguese" ];
	else if (["china", "hong-kong", "taiwan"].includes(c)) return [ "cantonese", "english", "mandarin" ];
	else if (c === "czech-republic") return [ "czech", "english" ];
	else if (["egypt", "saudi-arabia"].includes(c)) return [ "arabic", "english" ];
	else if (c === "france") return [ "english", "french" ];
	else if (c === "germany") return [ "english", "german" ];
	else if (c === "greece") return [ "english", "greek" ];
	else if (c === "hungary") return [ "english", "hungarian" ];
	else if (c === "iceland") return [ "english", "icelandic" ];
	else if (c === "india") return [
		"bengali", "english", "hindi", "kannada", "malayalam", "marathi", "punjabi", "tamil", "telugu"
	];
	else if (c === "indonesia") return [ "english", "indonesian" ];
	else if (c === "iran") return [ "english", "persian" ];
	else if (c === "ireland") return [ "english", "irish" ];
	else if (c === "israel") return [ "english", "hebrew" ];
	else if (c === "italy") return [ "english", "italian" ];
	else if (c === "japan") return [ "english", "japanese" ];
	else if (c === "nepal") return [ "english", "nepali" ];
	else if (c === "netherlands") return [ "dutch", "english" ];
	else if (c === "norway") return [ "english", "norwegian" ];
	else if (c === "pakistan") return [ "english", "urdu" ];
	else if (c === "philippines") return [ "english", "filipino" ];
	else if (c === "poland") return [ "english", "polish" ];
	else if (c === "romania") return [ "english", "romanian" ];
	else if (c === "russia") return [ "english", "russian" ];
	else if (c === "south-africa") return [ "english", "xhosa" ];
	else if (c === "south-korea") return [ "english", "korean" ];
	else if (c === "sweden") return [ "english", "swedish" ];
	else if (c === "thailand") return [ "english", "thai" ];
	else if (c === "turkey") return [ "english", "turkish" ];
	else if (c === "ukraine") return [ "english", "ukrainian" ];

	return Language_ALL_VAL;
};



/*
	------------------------
	Display Text Handlers
	------------------------
*/

export const Tag_to_Text = (s: string): string => {
	// Type
	if (s === "serie") return "TV Serie";
	else if (Type_ALL_VAL.includes(s as Type)) return s.charAt(0).toUpperCase() + s.slice(1) + "s";
	// PRContent Type
	else if (s === "deleted-scene") return "Deleted Scene";
	// Countries
	else if (s === "czech-republic") return "Czech Republic";
	else if (s === "hong-kong") return "Hong Kong";
	else if (s === "new-zealand") return "New Zealand";
	else if (s === "saudi-arabia") return "Saudi Arabia";
	else if (s === "south-korea") return "South Korea";
	else if (s === "uk") return "UK";
	else if (s === "usa") return "USA";
	// Genres
	else if (s === "game-show") return "Game-Show";
	else if (s === "reality-tv") return "Reality-TV";
	else if (s === "sci-fi") return "Sci-Fi";
	else if (s === "talk-show") return "Talk-Show";
	// Sort By
	else if (s === "content_date") return "New Release";
	else if (s === "prcontent_date") return "New Upload";
	else if (s === "shuffle") return "Shuffle";
	// Else
	else if (
		PRContent_Type_ALL_VAL.includes(s as PRContent_Type) ||
		Country_ALL_VAL.includes(s as Country) ||
		Language_ALL_VAL.includes(s as Language) ||
		Genre_ALL_VAL.includes(s as Genre)
	) return s.charAt(0).toUpperCase() + s.slice(1);

	return s;
	// else if (s === "") return "";
};

export const Type_Tag_to_Text = (t: Type): string => {
	if (t === "movie") return "Movie";
	else if (t === "serie") return "TV Serie";
	// return t.charAt(0).toUpperCase() + t.slice(1);
	return t;
};
